import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";

const NavBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
`

const NavItem = styled.div`
    width: 102px;
    height: 85px;
    margin-bottom: .5rem;
    position: relative;

    span {
        font-size: 70px;
        color: #fff;
        position: absolute;
        right: .5rem;
        bottom: 1rem;
        line-height: 60px;
        text-shadow: 1px 1px #222;
    }

    a {
        display: block;
        &.active {
            img {
                opacity: .6 !important;
            }
            span {
                color: ${props => props.theme.colors.green};
            }
        }
    }
`

const GalleryNav = (props) => {

    let navItems

    if (props.size === "small") {

        navItems = props.data.allSmallProjectsJson.edges.map(({ node }, i) => {
            const projectID = i + 1;
            return (
                <NavItem key={i}>
                    <Link to={node.fields.path} activeClassName="active">
                        <GatsbyImage image={node.images[0].childImageSharp.gatsbyImageData} alt="" />
                        <span>{projectID}</span>
                    </Link>
                </NavItem>
            );
        })

    } else {
        navItems = props.data.allLargeProjectsJson.edges.map(({ node }, i) => {
            const projectID = i + 1;
            return (
                <NavItem key={i}>
                    <Link to={node.fields.path} activeClassName="active">
                        <GatsbyImage image={node.images[0].childImageSharp.gatsbyImageData} alt="" />
                        <span>{projectID}</span>
                    </Link>
                </NavItem>
            );
        })
    }

    return(
        <NavBar>
            {navItems}
        </NavBar>
    )
}

export default (props) => (
    <StaticQuery
        query={graphql`{
  allLargeProjectsJson {
    edges {
      node {
        id
        images {
          childImageSharp {
            gatsbyImageData(width: 102, placeholder: NONE, layout: CONSTRAINED)
          }
        }
        fields {
          path
        }
      }
    }
  }
  allSmallProjectsJson {
    edges {
      node {
        id
        images {
          childImageSharp {
            gatsbyImageData(width: 102, placeholder: NONE, layout: CONSTRAINED)
          }
        }
        fields {
          path
        }
      }
    }
  }
}
`}
        render={data => {
            return (
                <GalleryNav data={data} size={props.size} />
            )
        }}
    />
)