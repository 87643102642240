import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import MaxContainer from "components/shared/MaxContainer"
import SliderWithText from "components/shared/SliderWithText"
import GalleryNav from "components/shared/GalleryNav"
import SEO from "utils/Seo"

const GalleryTemplateLarge = (props) => {
    const { metaTitle, metaDescription, title, textHTML, images } = props.data.largeProjectsJson
    
    return (
        <Layout>
            <SEO title={metaTitle} description={metaDescription} />
            <MaxContainer>
                <SliderWithText images={images}>
                    <h1>{title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: textHTML }} />
                </SliderWithText>
                <GalleryNav size="large" />
            </MaxContainer>
        </Layout>
    ) 
}

export default GalleryTemplateLarge

export const query = graphql`query ($id: String!) {
  largeProjectsJson(id: {eq: $id}) {
    metaTitle
    metaDescription
    title
    textHTML
    images {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
}
`
